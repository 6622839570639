import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LogoIcon = (props) => {
  return (
    <SvgIcon {...props} title="HaleyIssi Logo">
      {/* Your SVG markup */}
      <svg
        width="600mm"
        height="600mm"
        viewBox="0 0 600 600"
        version="1.1"
        id="svg1"
        xmlns="http://www.w3.org/2000/svg">
        <defs
            id="defs1" />
        <g
            id="layer1">
            <rect
            style={{display: "inline", fill: "#000000", fillOpacity: 0, stroke: "#000000", strokeWidth: 4, strokeDasharray: "none"}}
            id="rect1"
            width="600"
            height="600"
            x="0"
            y="0" />
            <path
            style={{display: "inline", fill: "#000000", stroke: "#000000", strokeWidth: 0}}
            d="M 7.867705,1132.9495 V 7.867705 H 1132.9495 2258.0313 V 1132.9495 2258.0313 H 1132.9495 7.867705 Z"
            id="path1"
            transform="scale(0.26458333)" />
            <path
            style={{display: "none", fill: "#000000", fillOpacity: 0, stroke: "#000000", strokeWidth: 4, strokeDasharray: "none"}}
            d="M 241.0794,-0.35376406 C 223.84372,9.669635 206.75137,19.609681 198.20249,45.406531 c -8.54888,25.796849 -8.54888,67.408069 0.0292,112.815359 8.57804,45.40729 25.45004,93.40112 42.18538,142.59309 16.73534,49.19197 32.74922,97.85479 40.9755,142.58682 8.22628,44.73203 8.22628,83.2605 0.20665,108.69392 -8.01963,25.43341 -23.98135,37.15217 -40.02199,48.92887"
            id="path15" />
            <path
            style={{display: "none", fill: "#000000", fillOpacity: 0, stroke: "#000000", strokeWidth: 4, strokeDasharray: "none"}}
            d="M 241.0794,-0.35376406 C 223.84372,9.669635 206.75137,19.609681 198.20249,45.406531 c -8.54888,25.796849 -8.54888,67.408069 0.0292,112.815359 8.57804,45.40729 25.45004,93.40112 42.18538,142.59309 16.73534,49.19197 32.74922,97.85479 40.9755,142.58682 8.22628,44.73203 8.22628,83.2605 0.20665,108.69392 -8.01963,25.43341 -23.98135,37.15217 -40.02199,48.92887"
            id="path15-2"
            transform="translate(119.83511)" />
            <path
            style={{display: "none", fill: "#000000", fillOpacity: 0, stroke: "#000000", strokeWidth: 4.07476, strokeDasharray: "none"}}
            d="m 470.06835,-8.8125738 c 0,0 0,624.2239838 0,624.2239838"
            id="path17"
            transform="matrix(1,0,0,0.96364317,14.803723,7.1875182)" />
            <path
            style={{display: "inline", fill: "#4bd0f9", fillOpacity: 1, stroke: "#000000", strokeWidth: 0, strokeDasharray: "none"}}
            d="M 9.9024971,1133.8359 V 8.2520809 H 229.40785 448.9132 V 1133.8359 2259.4197 H 229.40785 9.9024971 Z"
            id="path21"
            transform="scale(0.26458333)" />
            <path
            style={{display: "inline", fill: "#699dfa", fillOpacity: 1, stroke: "#000000", strokeWidth: 0, strokeDasharray: "none"}}
            d="M 465.41736,1133.8359 V 8.2520809 H 670.86664 876.31593 L 854.88805,22.078801 c -31.0705,20.048795 -75.58436,65.740471 -90.86095,93.265089 -32.88592,59.25225 -47.78239,134.7584 -47.71242,241.84113 0.0867,132.67603 21.53172,263.35324 72.60799,442.44333 9.19007,32.22346 51.45562,162.56428 93.92343,289.64635 134.7975,403.3724 167.2223,523.6896 186.8068,693.1748 7.2975,63.1532 8.7906,169.8529 3.0253,216.2045 -13.7586,110.6166 -53.4187,181.3928 -136.55927,243.6995 l -22.77287,17.0662 H 689.38171 465.41736 Z"
            id="path22"
            transform="scale(0.26458333)" />
            <path
            style={{display: "inline", fill: "#7a4bee", fillOpacity: 1, stroke: "#000000", strokeWidth: 0, strokeDasharray: "none"}}
            d="m 957.24138,2250.4394 c 48.54802,-37.43 91.03812,-92.8969 108.98332,-142.2676 46.6067,-128.224 37.9752,-327.5483 -24.83,-573.3919 C 1014.8431,1430.8468 987.86679,1344.2394 901.0874,1084.3234 820.94136,844.27531 794.32648,757.22454 773.9675,668.54537 742.16031,530.00036 729.1289,405.23259 736.05932,305.59781 746.62498,153.70102 784.22865,85.485052 893.30694,20.338426 L 913.54371,8.2520809 h 207.28319 207.2832 l -24.5188,16.7661281 c -76.5509,52.346083 -113.2621,117.454561 -129.2031,229.145881 -5.5332,38.76859 -5.4265,169.83954 0.18,221.15577 11.3219,103.6296 36.7837,221.72061 76.592,355.23162 11.0325,37.00121 54.0798,169.02308 95.6607,293.38192 87.0724,260.4132 101.7312,307.7816 130.2396,420.8561 42.6946,169.3418 59.8582,308.1529 51.5073,416.5657 -10.4944,136.2391 -42.4572,202.8967 -131.8137,274.8945 l -28.5591,23.0111 -211.2533,0.043 -211.25323,0.043 z"
            id="path23"
            transform="scale(0.26458333)" />
            <path
            style={{display: "inline", fill: "#c752ef", fillOpacity: 1, stroke: "#000000", strokeWidth: 0, strokeDasharray: "none"}}
            d="m 1418.3613,2243.4679 c 95.5948,-79.625 129.752,-168.1745 129.7178,-336.2819 -0.028,-135.6899 -23.2322,-271.2411 -80.6844,-471.3239 -11.9898,-41.7556 -56.5469,-179.8954 -99.0158,-306.9774 C 1266.8365,825.03361 1244.8134,752.0629 1219.9426,637.06064 1195.3992,523.57252 1186.7737,439.0962 1189.1605,335.5911 1193.1611,162.11031 1230.2909,88.761455 1349.7683,18.315058 l 17.0668,-10.0629771 h 227.6122 227.6122 V 1133.8359 2259.4197 h -211.4247 -211.4246 z"
            id="path24"
            transform="scale(0.26458333)" />
            <path
            style={{display: "inline", fill: "#e36992", fillOpacity: 1, stroke: "#000000", strokeWidth: 0, strokeDasharray: "none"}}
            d="M 1841.8645,1133.8359 V 8.2520809 h 207.9524 207.9524 V 1133.8359 2259.4197 h -207.9524 -207.9524 z"
            id="path25"
            transform="scale(0.26458333)" />
            <path
            style={{display: "none", fill: "#000000", fillOpacity: 0, stroke: "#000000", strokeWidth: 4.04855, strokeDasharray: "none"}}
            d="m 120.84933,-0.69011513 c 0,0 0,616.48852513 0,616.48852513"
            id="path16"
            transform="matrix(1,0,0,0.97615785,0,-0.01645383)" />
        </g>
        </svg>

    </SvgIcon>
  )
}

export default LogoIcon 
